import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react'
import './PopularPost.scss'

const PopularPost = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { order: DESC, fields: date },limit : 12)  {
        nodes {
          slug
          date
          Title
          id
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          blogPara
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 72)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="popular-posts pt-lg-5 pt-sm-5">
      <div className="title pb-3">
        <span>Popular Stories</span>
      </div>
      <div className="row gx-sm-3">
        {data.allStrapiPost.nodes.slice(6, 12).map((e, i) => {
          return (
            <>
              <div className='col-md-6 d-flex align-items-baseline' style={{ gap: '16px' }}>
                <Link to={`/${e.slug}/`}>
                  <div className="Popular-image">
                    <GatsbyImage
                      image={e.image.localFile?.childImageSharp?.gatsbyImageData}
                      alt="polular-post"
                      loading="eager"
                      className="img-fluid"
                      objectFit="cover"
                      style={{
                        width: '72px',
                        borderRadius: '5px'
                      }}
                    />
                  </div>
                </Link>
                <div className="popular_post pt-1">
                  <h2 className="pb-2">
                    <Link
                      to={`/${e.slug}/`}
                      dangerouslySetInnerHTML={{ __html: e.Title }}
                    ></Link>
                  </h2>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
export default PopularPost